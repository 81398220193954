<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";

export default {
    page: {
        title: "Buat Izin Penggunaan TUK",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Buat Izin Penggunaan TUK",
            items: [
                {
                    text: "penggunaan TUK",
                    href: "/penggunaan-tuk",
                },
                {
                    text: "Buat Izin Penggunaan TUK",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,

            // variabel referensi
            optionsMenuParent: [],

            //modal
            showModalSimpan: false,

            // meta_asesor: [
            //     {
            //         'tuk': 'Ruang Meeting LSP',
            //         'tanggal_asesmen': '1 Agustus 2023'
            //     },
            // ],
            // meta_tuk_penggunaan: [
            //     {
            //         'tuk_id': '',
            //         'tuk_nama': '',
            //         'tanggal_asesmen': ''
            //     },
            // ],
            optionJadwalPendaftaran : [],
            optionTuk : [],
            optionDivisi : [],
            selectedTuk : '',
            urutan_nomor: null,
            no_surat_pendaftaran: null,
            kode_jadwal: null,
            form_penggunaan_tuk : {
                nomor_surat_penggunaan : '',
                perihal : 'Permohonan Izin Pemakaian Ruangan sebagai Tempat Uji Kompetensi LSP BUMN Farmasi',
                tanggal_surat_penggunaan : new Date().toISOString().slice(0, 10),
                jadwal_pendaftaran_id : '',
                nama_sertifikasi : '',
                urutan_nomor : '',
                jadwal_pendaftaran: '',
            },
            preview_surat_penggunaan : null,
            id_penggunaan_tuk : null,
            meta_kepala_dept: [
                {
                    'id_divisi': '',
                    'nama_divisi': '',
                },
            ],
        };
    },
    mounted() {
        this.getJadwalPendaftaran();
        this.getTuk();
        this.getNomorPenggunaan();
        this.getDiVisi();
    },
    methods: {
        addAsesor() {
            this.meta_asesor.push({
                tuk: "",
                tanggal_asesmen: "",
            });
        },
        removeAsesor(index) {
            this.meta_asesor.splice(index, 1);
        },
        modalSimpan() {
            let self = this;
            self.showModalSimpan = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalSimpan = true;
            });
        },
        StoreData() {
            let self = this;
            self.preview_surat_penggunaan = null;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config = {
                method : "post",	
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "penggunaan-tuk/store-penggunaan-tuk",
                data:  {
                    nomor_surat_penggunaan : self.form_penggunaan_tuk.nomor_surat_penggunaan,
                    perihal : self.form_penggunaan_tuk.perihal,
                    tanggal_surat_penggunaan : self.form_penggunaan_tuk.tanggal_surat_penggunaan,
                    urutan_nomor : self.form_penggunaan_tuk.urutan_nomor,
                    meta_tuk_penggunaan : self.meta_tuk_penggunaan,
                    meta_kepala_dept : self.meta_kepala_dept,
                    status_id : "fd835362-4b92-11ee-913b-0242ac110006", // status draft penggunaan Tuk
                    qr : false,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            }
            axios(config)
                .then(function (response) {
                    let res = response.data.data;
                    self.preview_surat_penggunaan = res.pdf;
                    self.id_penggunaan_tuk = res.data_surat_penggunaan.id;
                    Swal.close();
                })
        },

        getJadwalPendaftaran(){
            let self = this;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-pendaftaran-pra-asesmen",   
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    self.optionJadwalPendaftaran = response.data.data.referensi;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        getSkemabyChange(){
            // let self = this;
            // //     self.form_penggunaan_tuk.nama_sertifikasi = self.selectedJadwalPendaftaran.pendaftaran_skema[0].skema_nama;
            //     self.form_penggunaan_tuk.jadwal_pendaftaran = self.selectedJadwalPendaftaran;
            //     self.form_penggunaan_tuk.jadwal_pendaftaran_id = self.selectedJadwalPendaftaran.id;
            //     self.form_penggunaan_tuk.nama_sertifikasi = self.selectedJadwalPendaftaran.pendaftaran_skema[0].skema_nama;

            // let data_jadwal = self.optionJadwalPendaftaran.find(x => x.id == self.selectedJadwalPendaftaran.id);
           
            // data_jadwal.pendaftaran_skema.forEach(function (item) {
            //     self.form_penggunaan_tuk.nama_sertifikasi = item.skema_nama;
            // });

        },
        
        addTUK(){
            this.meta_tuk_penggunaan.push({
                tuk_id: "",
                tuk_nama: "",
                tanggal_asesmen: "",
            });
        },
        removeTUK(index){
            this.meta_tuk_penggunaan.splice(index, 1);
        },
        getTuk(){
            let self = this;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-tuk",   
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    self.optionTuk = response.data.data.referensi;
                    console.log(self.optionTuk);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getDiVisi(){
            let self = this;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-departement-tuk",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    self.optionDivisi = response.data.data.referensi;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        convertToRoman(num) {
            var roman = {
                M: 1000,
                CM: 900,
                D: 500,
                CD: 400,
                C: 100,
                XC: 90,
                L: 50,
                XL: 40,
                X: 10,
                IX: 9,
                V: 5,
                IV: 4,
                I: 1
            };
            var str = '';

            for (var i of Object.keys(roman)) {
                var q = Math.floor(num / roman[i]);
                num -= q * roman[i];
                str += i.repeat(q);
            }

            return str;
        },
        
        getNomorPenggunaan() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "penggunaan-tuk/get-nomor-penggunaan-tuk", {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then((response) => {
                    var next_nomor = response?.data?.data?.next_urutan;
                    var currentDate = new Date();
                    console.log(currentDate)
                    self.form_penggunaan_tuk.nomor_surat_penggunaan = next_nomor + "/LSP/UM/" + self.convertToRoman(currentDate.getMonth() + 1) + "/" + currentDate.getFullYear();
                    self.kode_jadwal = next_nomor + "/LSP/UM/" + self.convertToRoman(currentDate.getMonth()) + "/" + currentDate.getFullYear();
                    self.form_penggunaan_tuk.urutan_nomor = next_nomor;
                   
                    Swal.close();
                })
                .catch((e) => {
                    console.log(e);
                    // this.axiosCatchError = e.response.data.data;
                    Swal.close();
                });
        },
        validasiStore(){
            if (this.form_penggunaan_tuk.nomor_surat_penggunaan == '') {
                Swal.fire({
                    icon: "info",
                    title: "Oops...",
                    text: "Nomor Surat Penggunaan TUK tidak boleh kosong",
                });
                return false;
            }
            if (this.form_penggunaan_tuk.tanggal_surat_penggunaan == '') {
                Swal.fire({
                    icon: "info",
                    title: "Oops...",
                    text: "Tanggal Surat Penggunaan TUK tidak boleh kosong",
                });
                return false;
            }
            // if (this.form_penggunaan_tuk.jadwal_pendaftaran 
            //     Swal.fire({
            //         icon: "info",
            //         title: "Oops...",
            //         text: "No Jadwal Pendaftaran tidak boleh kosong",
            //     });
            //     return false;
            // }
            
            // if(this.meta_tuk_penggunaan[0].tuk == '' && this.meta_tuk_penggunaan[0].tanggal_asesmen == ''){
            //     Swal.fire({
            //         icon: "info",
            //         title: "Oops...",
            //         text: "TUK tidak boleh kosong",
            //     });
            //     return false;
            // }
            // if meta_tuk_penggunaan is empty
            // if(this.meta_tuk_penggunaan.length == 0){
            //     Swal.fire({
            //         icon: "info",
            //         title: "Oops...",
            //         text: "TUK tidak boleh kosong",
            //     });
            //     return false;
            // }
            else {
                this.StoreData();
            }
        },
        
        getTukName(index){
            let self = this;
            self.meta_tuk_penggunaan[index].tuk_nama = self.optionTuk.find(x => x.id == self.meta_tuk_penggunaan[index].tuk_id).tuk_nama;
            console.log(self.meta_tuk_penggunaan);
        },

        simpanSurat(){
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "penggunaan-tuk/ubah-status",
                data: {
                    id: self.id_penggunaan_tuk,
                    status: "submit-admin-lsp",
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman list penggunaan tuk",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            // close all modal
                            self.$router.push({ name: "all-penggunaan_tuk" });
                        }
                    });
                })
                .catch(function (error) {
                    // this.axiosCatchError = e.response;
                    Swal.fire({
                        icon: "error",
                        title: "Gagal",
                        text: error.response?.data?.data?.error,
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    console.log(error);
                    self.axiosCatchError = error.response.data.data.error;
                });
        },
        addKepalaDepartemen(){
            this.meta_kepala_dept.push({
                id_divisi: "",
                nama_divisi: "",
            });
        },  
        changeDivisi(index){
            let self = this;
            self.meta_kepala_dept[index].nama_divisi = self.optionDivisi.find(x => x.id == self.meta_kepala_dept[index].id_divisi).departemen_tuk_nama;
        },
        removeKepalaDepartemen(index){
            this.meta_kepala_dept.splice(index, 1);
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="validasiStore">
                        <div class="card-body">
                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' ||
                                    axiosCatchError instanceof String
                                    ">
                                    {{ axiosCatchError }}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                            <span class="text-danger">{{ allErrors[0] }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="text-center">
                                        <h3>Formulir Surat Penggunaan Tempat Uji Kompetensi</h3>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Tanggal Surat Penggunaan</label>
                                                <input type="date" name="" id="" class="form-control" v-model="form_penggunaan_tuk.tanggal_surat_penggunaan">   
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Nomor Surat Penggunaan</label>
                                                <input type="text" name="" id="" class="form-control" v-model="form_penggunaan_tuk.nomor_surat_penggunaan">
                                            </div>
                                           
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Perihal</label>
                                                <input type="text" name="" id="" class="form-control" v-model="form_penggunaan_tuk.perihal">
                                            </div>
                                            <!-- <div class="form-group col-md-12">
                                                <label for="tipe_menu">Nama sertifikasi</label>
                                                <input type="text" name="" id="" class="form-control" readonly v-model="form_penggunaan_tuk.nama_sertifikasi">
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12 mt-3">
                                        <label for="tipe_menu">Surat Ditujukan Kepada</label>
                                        <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                            <thead class="bg-dark text-center text-white">
                                                <tr>
                                                    <th>Kepala Departemen / Divisi</th>
                                                    <th><button type="button" class="btn btn-sm btn-success"
                                                            @click="addKepalaDepartemen()"><i class="fa fa-plus"></i></button></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="meta_kepala_dept.length == 0">
                                                    <td colspan="3">TIdak Ada Data</td>
                                                </tr>
                                                <tr v-for="(item, index) in meta_kepala_dept" :key="index">
                                                    <td>
                                                        <!-- <select name="" id="" class="form-select" v-model="item.id_divisi" @change="changeDivisi(index)">
                                                            <option value="">Pilih Depertemen / Divisi</option>
                                                            <option v-for="(item, index) in optionDivisi" :key="index" :value="item.id">
                                                                {{ item.departemen_tuk_nama }}  
                                                            </option>
                                                        </select> -->

                                                        <v-select v-model="item.divisi" :options="optionDivisi" label="departemen_tuk_nama" @input="changeDivisi(index)" />
                                                    </td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-sm btn-danger" @click="removeKepalaDepartemen(index)">
                                                            <i class="fa fa-times"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- <div class="form-group col-md-12 mt-3">
                                        <label for="tipe_menu">TEMPAT UJI KOMPENTENSI</label>
                                        <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                            <thead class="bg-dark text-center text-white">
                                                <tr>
                                                    <th>TUK</th>
                                                    <th>Tanggal Asesmen</th>
                                                    <th><button type="button" class="btn btn-sm btn-success"
                                                            @click="addTUK()"><i class="fa fa-plus"></i></button></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="meta_tuk_penggunaan.length == 0">
                                                    <td colspan="3">TIdak Ada Data</td>
                                                </tr>
                                                <tr v-for="(item, index) in meta_tuk_penggunaan" :key="index">
                                                    <td>
                                                        <select name="" id="" class="form-select" v-model="item.tuk_id" @change="getTukName(index)">
                                                            <option value="">Pilih Tempat Uji Kompetensi</option>
                                                            <option v-for="(item, index) in optionTuk" :key="index" :value="item.id">
                                                                {{ item.tuk_nama }}
                                                            </option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input type="date" class="form-control" v-model="item.tanggal_asesmen" placeholder="Tanggal Asesmen" required>
                                                    </td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-sm btn-danger" @click="removeTUK(index)">
                                                            <i class="fa fa-times"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> -->
                                    <div class="row mt-2">
                                        <div class="col-md-12 col-md-6">
                                            <div class="text-end">
                                                <b-button v-on:click="modalSimpan" type="submit" variant="primary" class="m-1"><i
                                                        class="fa fa-refresh"></i>
                                                    Generate surat penggunaan TUK</b-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="alert alert-info mt-3" v-if="!preview_surat_penggunaan"><i class="fas fa-info"></i> Generate Surat Penggunaan Terlebih Dahulu</div>
                                    <div class="row" v-if="preview_surat_penggunaan">
                                        <h3>Preview Surat Penggunaan TUK</h3>
                                        <div class="row p-4">
                                            <div class="card" style="outline-style: outset">
                                                <div class="card-body">
                                                    <iframe v-bind:src="preview_surat_penggunaan" width="100%" height="400"></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="preview_surat_penggunaan">
                            <div class="col-md-12 col-md-6">
                                <div class="text-end">
                                    <b-button v-on:click="simpanSurat" type="button" variant="primary" class="m-1"><i
                                            class="fa fa-refresh"></i>
                                        Simpan surat penggunaan TUK</b-button>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="card-footer">
                            <div class="row">
                                <div class="col-md-12 col-md-6">
                                    <div class="text-end">
                                        <b-button type="button" class="m-1" variant="warning"><i
                                                class="fas fa-download"></i>
                                            Unduh PDF</b-button>
                                        <b-button v-on:click="modalSimpan" type="submit" variant="primary" class="m-1"><i
                                                class="fa fa-save"></i>
                                            Simpan</b-button>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
    <!-- <b-modal v-model="showModalSimpan" title="Konfigurasi Atasan" title-class="text-black font-18" body-class="p-3"
        size="md" hide-footer>
        <div class="row">
            <form @submit.prevent="StoreData">
                <div class="form-group col-md-12">
                    <label for="tipe_menu">Manajer Sertifikasi</label>
                    <select name="" id="" class="form-control">
                        <option value="">Pilih Manajer Sertifikasi</option>
                        <option value="">Manajer Sertifikasi 1</option>
                        <option value="">Manajer Sertifikasi 2</option>
                    </select>
                </div>
                <div class="form-group col-md-12">
                    <label for="tipe_menu">Manajer Pelatihan</label>
                    <select name="" id="" class="form-control">
                        <option value="">Pilih Manajer Pelatihan</option>
                        <option value="">Manajer Pelatihan 1</option>
                        <option value="">Manajer Pelatihan 2</option>
                    </select>
                </div>
                <div class="form-group col-md-12">
                    <label for="tipe_menu">Ketua TUK</label>
                    <select name="" id="" class="form-control">
                        <option value="">Pilih Ketua TUK</option>
                        <option value="">Ketua TUK 1</option>
                        <option value="">Ketua TUK 2</option>
                    </select>
                </div>

                <div class="col-md-12 text-end mt-3">
                    <button class="btn btn-primary" type="submit"><i class="fas fa-save"></i> Simpan</button>
                </div>
            </form>
        </div>
    </b-modal> -->
</template>
